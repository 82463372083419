<template>
  <v-card>
    <v-card-text v-if="companyId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :disable-pagination="workerList !== undefined"
        :hide-default-footer="workerList !== undefined"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            title="Şirket Çalışanları"
            icon="mdi mdi-badge-account-horizontal"
            :add-route="{ name: 'my-company.workers.create' }"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            @click:edit="handleEditClick"
            :hide-edit="!can('edit-company-user')"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            :show-delete="can('delete-company-user')"
            delete-confirmed
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleDeleteClick"
            v-if="showHeader && $refs.headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'my-company.workers.edit',
              params: { id: item.company_user_id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: 'my-company.workers.show',
              params: { id: item.company_user_id },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ item }">
          <rs-table-cell-boolean :value="item.is_active" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.has_push_notifications="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.email="{ item }">
          <a :href="`mailto:${item.email}`" v-if="item.email">
            {{ item.email }}
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.phone="{ item }">
          <a :href="`tel:${item.phone}`" v-if="item.phone">
            {{ item.phone }}
          </a>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-else>Bir yönetim şirketi seçmelisiniz.</v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasPermissions, hasDataTable } from "@/view/mixins";

export default {
  name: "CompanyWorkerList",
  mixins: [filtersToURL, hasPermissions, hasDataTable],
  props: {
    showEdit: {
      type: Boolean,
      default: false,
      required: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
      required: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
      required: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
      required: false,
    },
    workerList: {
      type: Array,
      default: undefined,
      required: false,
    },
    companyId: {
      type: Number,
      default: null,
      required: false,
    },
    writeToUrl: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      headers: [],
    };
  },
  created() {
    if (this.showEdit) {
      this.headers.push({
        text: "",
        value: "edit",
        searchable: false,
        sortable: false,
        width: "30px",
        align: "center",
      });
    }

    this.headers = this.headers.concat([
      {
        text: "Ad Soyad",
        value: "name",
        searchable: "text",
        sortable: true,
      },
      {
        text: this.$t("labels.user_role"),
        value: "role_name",
        searchable: "text",
      },
      {
        text: "E-Posta",
        value: "email",
        searchable: "text",
      },
      {
        text: "Telefon",
        value: "phone",
        searchable: "text",
      },
      {
        text: "Görevi",
        value: "position",
        searchable: "text",
      },
      {
        text: this.$t("labels.status"),
        value: "is_active",
        searchable: "isActive",
        align: "center",
      },
      {
        text: this.$t("labels.has_push_notifications"),
        value: "has_push_notifications",
        searchable: "yesNo",
        align: "center",
      },
      {
        text: this.$t("labels.mobile_os"),
        value: "mobile_os",
        searchable: "select",
        options: [
          {
            id: "android",
            name: "Android",
          },
          {
            id: "ios",
            name: "iOS",
          },
        ],
        itemText: "name",
        align: "center",
      },
    ]);

    if (this.workerList !== undefined) {
      this.list = this.workerList;
    } else if (this.companyId) {
      if (this.writeToUrl) {
        this.loadURLParams();
      } else {
        this.loadList();
      }
    }
  },
  watch: {
    workerList: {
      handler() {
        this.list = this.workerList;
      },
    },
    companyId() {
      this.loadList();
    },
    options: {
      handler() {
        this.options.page = 1;
        if (this.writeToUrl) {
          this.setURLParams();
        } else {
          this.loadList();
        }
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        if (this.writeToUrl) {
          this.setURLParams();
        } else {
          this.loadList();
        }
      }),
      deep: true,
    },
  },
  methods: {
    /**
     * Retrieves the list of company from api
     * @return {Promise} Resolves if list is retrieved
     */
    loadList() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.companyId) {
        params.company_id = this.companyId;
      }

      this.$api
        .query("company-users", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "my-company.workers.edit",
          params: { id: this.selectedItems[0].company_user_id },
        });
      }
    },
    handleDeleteClick() {
      if (this.selectedItems.length === 1) {
        this.isLoading = true;

        this.$api
          .delete(`company-users/${this.selectedItems[0].company_user_id}`)
          .then(() => {
            this.$toast.success("Silindi");
            setTimeout(() => this.loadList(), 100);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
