<template>
  <div>
    <CompanyWorkerList
      v-if="company"
      :show-edit="true"
      :show-header="true"
      :show-add="true"
      :show-search="true"
      :company-id="company.id"
      write-to-url
    />

    <v-card v-if="!company">
      <v-card-text>
        Listeyi görüntülemek için üst menüden bir yönetim şirketi seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CompanyWorkerList from "@/view/content/CompanyWorkerList";

export default {
  components: {
    CompanyWorkerList,
  },
  computed: {
    ...mapGetters(["company"]),
  },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>
