var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.companyId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"disable-pagination":_vm.workerList !== undefined,"hide-default-footer":_vm.workerList !== undefined,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.showHeader && _vm.$refs.headSearch)?_c('rs-table-title-bar',{attrs:{"title":"Şirket Çalışanları","icon":"mdi mdi-badge-account-horizontal","add-route":{ name: 'my-company.workers.create' },"hide-edit":!_vm.can('edit-company-user'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query,"show-delete":_vm.can('delete-company-user'),"delete-confirmed":"","delete-enabled":_vm.selectedItems.length === 1},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)},"click:delete":_vm.handleDeleteClick}}):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'my-company.workers.edit',
            params: { id: item.company_user_id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'my-company.workers.show',
            params: { id: item.company_user_id },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.is_active",fn:function({ item }){return [_c('rs-table-cell-boolean',{attrs:{"value":item.is_active}})]}},{key:"item.has_push_notifications",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.email",fn:function({ item }){return [(item.email)?_c('a',{attrs:{"href":`mailto:${item.email}`}},[_vm._v(" "+_vm._s(item.email)+" ")]):_vm._e()]}},{key:"item.phone",fn:function({ item }){return [(item.phone)?_c('a',{attrs:{"href":`tel:${item.phone}`}},[_vm._v(" "+_vm._s(item.phone)+" ")]):_vm._e()]}}],null,false,1787647981),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1):_c('v-card-text',[_vm._v("Bir yönetim şirketi seçmelisiniz.")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }